import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { AppProvider } from 'context/AppContext';
import { ChatProvider } from './context/ChatContext';
import App from 'app/App';
import { GlobalStyles } from 'assets/style/common.styled';
import 'index.css';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <AppProvider>
                <ChatProvider>
                    <GlobalStyles/>
                    <App/>
                </ChatProvider>
            </AppProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
);
