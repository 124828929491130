import React, { FC } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Path } from 'routes';
import { useApp } from 'context/AppContext';
import { ReactComponent as IconLogo } from 'logo.svg';
import { ReactComponent as IconMenu } from 'assets/icons/header-menu.svg';
import { ReactComponent as IconBack } from 'assets/icons/header-back.svg';
import { Component, Logo, Nav, Menu } from './Header.styled';

export const Header:FC = () => {
    const { title, menu } = useApp();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const goBack = () => {
        const parts = pathname.split('/');
        const userId = parts[parts.length - 1];

        if (pathname.includes('user') && +userId) {
            navigate(`/chat/${userId}`);
            return;
        }

        if (pathname.includes('compatibility')) {
            navigate(`/`);
            return;
        }

        navigate(-1);
    }

    if (pathname === Path.private.registration) return null;

    return (
        <Component>
            { pathname === Path.public.main
                ? (
                    <Logo>
                        <Link to={Path.public.main}>
                            <IconLogo/>
                            <h1>{ title }</h1>
                        </Link>
                    </Logo>
                )
                : (
                    <Nav onClick={goBack}>
                        <IconBack/>
                        <h1>{ title }</h1>
                    </Nav>
                )
            }
            <Menu isActiveMenu={menu.active}>
                <span>18+</span>
                <button name="menu" onClick={menu.toggle}>
                    <IconMenu/>
                </button>
            </Menu>
        </Component>
    );
};
