import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { BASE_URL, getDefaultHeaders, getDynamicHeaders, Entity } from '../config';
import {
    IUser, IUserId, IResult, ILike, IPagination,
    ICriteria, IFoundByCriteria, IHobbyId, ILikedUser, ISetCriteria,
    ICompatibility,
} from './types';

export const usersAPI = createApi({
    reducerPath: 'users',
    baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
    tagTypes: ['Filters'],
    endpoints: (build) => ({
        getFeed: build.query<IUser[], IPagination>({
            query: (payload) => ({
                url: `${Entity.profiles}?limit=${payload.limit}&offset=${payload.offset}`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        getSelectionByHobby: build.query<IUser[], IHobbyId>({
            query: (payload) => ({
                url: `${Entity.profiles}/by-hobby/${payload.id}?limit=${payload.limit}&offset=${payload.offset}`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        getUser: build.query<IUser, IUserId>({
            query: (payload) => ({
                url: `${Entity.profiles}/${payload.id}`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        getFilters: build.query<ICriteria, void>({
            query: () => ({
                url: `${Entity.profiles}/criteria-list`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
            providesTags: () => ['Filters']
        }),
        getLikedByMe: build.query<ILikedUser[], IPagination>({
            query: (payload) => ({
                url: `${Entity.likes}/from-me?limit=${payload.limit}&offset=${payload.offset}`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        getLikedByUser: build.query<ILikedUser[], IPagination>({
            query: (payload) => ({
                url: `${Entity.likes}/to-me?limit=${payload.limit}&offset=${payload.offset}`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        getCompatibility: build.query<ICompatibility, string>({
            query: (payload) => ({
                url: `${Entity.profiles}/${payload}/compatibility`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        postReaction: build.mutation<IResult, ILike>({
            query: (payload) => ({
                url: `${Entity.likes}/profile/${payload.id}`,
                method: 'PUT',
                body: { add: payload.add },
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        getBlockedUsers: build.query<IUser[], IPagination>({
            query: (payload) => ({
                url: `${Entity.profiles}/blocklist?limit=${payload.limit}&offset=${payload.offset}`,
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
            keepUnusedDataFor: 1,
        }),
        postBlock: build.mutation<IResult, IUserId>({
            query: (payload) => ({
                url: `${Entity.profiles}/${payload.id}/blocklist`,
                method: 'POST',
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            })
        }),
        // <==== This is alternative method for "deleteProfile" ==============>
        // <==== because of A1 operator blocks all DELETE method types =======>
        deleteBlockAlternative: build.mutation<IResult, IUserId>({
            query: (payload) => ({
                url: `${Entity.profiles}/${payload.id}/blocklist/delete`,
                method: 'POST',
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            }),
        }),
        // <==================================================================>
        deleteBlock: build.mutation<IResult, IUserId>({
            query: (payload) => ({
                url: `${Entity.profiles}/${payload.id}/blocklist`,
                method: 'DELETE',
                headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
            })
        }),
        postFilters: build.mutation<IFoundByCriteria, ISetCriteria|[]>({
            query: (payload) => {
                return ({
                    url: `${Entity.profiles}/criteria-list`,
                    method: 'PUT',
                    body: payload,
                    headers: { ...getDefaultHeaders(), ...getDynamicHeaders() },
                })
            },
            invalidatesTags: () => ['Filters'],
        }),
    }),
});